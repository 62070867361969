import React from "react"

const NotFoundPage = () => (
  <>
    <h1>NOT FOUND</h1>
    <p>Aceasta pagina nu exista.</p>
  </>
)

export default NotFoundPage
